/*
    This program is designed to identify preferences for video (YouTube) content. The program uses
    the multiple-stimulus-without-replacement (MSWO) preference assessment methodology (Carr,
    Nicolson, & Higbee, 2000; Deleon & Iwata, 1996). The design and functions are based on the
    web-based MSWO program described in Curiel, Curiel, Li, Deochand, and Poling (2018)
    Copyright (C) 2019 Antonio Adame assigned to Board of Regents of the University of Texas System.

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/>. */

import React, { Component } from 'react';
import $ from 'jquery';
import '../App.scss';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import NavigationIcon from '@material-ui/icons/Navigation';
import Collapse from '@material-ui/core/Collapse';

import VideoChecklist from './VideoChecklist';
import AddVideoChecklist from './AddVideoChecklist';
import ParameterInputs from './ParameterInputs';
import Instructions from '../assets/files/Instructions.pdf';
import SourceCode from '../assets/files/mswo-source code.zip'


const style = theme => (
  {
    fab_add: {
      position: "absolute",
      bottom: "1rem",
      right: "1rem",
    },
    fab_refresh: {
      position: "absolute",
      bottom: "5rem", 
      right: "1.73em",
      width: "40px",
      height: "40px",
    }
  }
)


class Home extends Component {

  constructor() {
    super();
    this.state = {
      expanded: false,
      playTime: 1,
      idleTimeout: 1,
      repeatAmount: 1,
      videos: [],
      selectedAmt: 0,
    }
  }  
  componentWillMount() {
    $('body').removeClass('disable-click')
    // clear seen list. 
    localStorage.setItem('seenList', JSON.stringify([]));

    //Some variables in the localStorage need to be set to a default from the beginning. 
    if ( !localStorage.getItem('playTime') ) localStorage.setItem('playTime', 1);
    if ( !localStorage.getItem('idleTimeout') ) localStorage.setItem('idleTimeout', 1);
    if ( !localStorage.getItem('repeatAmount') || localStorage.getItem('repeatAmount') < 1 ) localStorage.setItem('repeatAmount', 1);

    let videos = JSON.parse(localStorage.getItem('videos'));
    let selectedAmount = localStorage.getItem('selectedAmount');
    let playTime = localStorage.getItem('playTime') < 1 ? 1 : localStorage.getItem('playTime');
    let idleTime = localStorage.getItem('idleTimeout') < 1 ? 1 : localStorage.getItem('idleTimeout');
    let repeatAmount = localStorage.getItem('repeatAmount') < 1 ? 1 : localStorage.getItem('repeatAmount');

    if (!selectedAmount) selectedAmount = 0;
    if (videos) this.setState( {videos: videos} );
    if (selectedAmount) this.setState( {selectedAmt: selectedAmount} );
    if (playTime) this.setState( {playTime: playTime} );
    if (idleTime) this.setState( {idleTimeout: idleTime} );
    if (repeatAmount) this.setState( {repeatAmount: repeatAmount} );

    //localStorage.clear();
    //We use this item to update our repeat variable between sessions,
    //Without affecting the global repeat parameter.
    localStorage.setItem('currentRepeatAmount', repeatAmount);
  }

  handleCheckToggle = (index, toggle) => {
    // make new state array of videos to change the check status.
    const newVids = this.state.videos.slice();
    let selectedAmt = parseInt( this.state.selectedAmt );

    newVids[index].checked = toggle;
    if ( toggle == false ) {
        this.setState( {selectedAmt: selectedAmt - 1} )
        this.props.SetSelections( selectedAmt - 1 )

        localStorage.setItem( 'selectedAmount', selectedAmt - 1 )
    }
    else {
        this.setState( {selectedAmt: selectedAmt + 1} )
        this.props.SetSelections( selectedAmt + 1 )

        localStorage.setItem('selectedAmount', selectedAmt + 1 )
    }

    this.setState( {videos: newVids} );
    localStorage.setItem('videos', JSON.stringify(newVids));
  }
  handleAddVideoChecklist = ( newVideo ) => {
    let videos = this.state.videos;
    let selectedAmt = parseInt( this.state.selectedAmt) + 1;

    videos.push( newVideo );
    this.setState( {selectedAmt: selectedAmt} )
    this.props.SetSelections( selectedAmt )

    localStorage.setItem( 'videos', JSON.stringify(this.state.videos) );
    localStorage.setItem( 'selectedAmount', selectedAmt )

    this.props.SetVideo( videos );
  }
  handleParamChange = (name, event) => {
      this.setState({name: event.target.value})
      this.props.SetTime(name, event.target.value);
      localStorage.setItem(name, event.target.value);
  }
  componentWillUnmount() {
    this.props.handleSeen(null);
    let repeatAmount = localStorage.getItem('repeatAmount') < 1 ? 1 : 
                        localStorage.getItem('repeatAmount');
    localStorage.setItem('currentRepeatAmount', repeatAmount);
  }
  componentDidUpdate() {
    if (this.state.videos.length == 0) {
      $('#clear-widget').hide();
    }
    if (this.state.videos.length > 0) {
      $('#clear-widget').show();
    }
  }
  componentDidMount() {
    let videos = JSON.parse(localStorage.getItem('videos'));
    if (!videos)       {
      $('#clear-widget').hide();
      return;
    }
    if (videos.length == 0) {
      $('#clear-widget').hide();
      return;
    }
  }
  render() {
    const {classes} = this.props;
    var ConditionalLink = (this.state.selectedAmt <= 5) && (this.state.selectedAmt > 0) ? 
                              Link : 'div';

    return (

      <div className="Home">
        <h1 className="page-title">MSWO Preference Assessment Tool</h1>
        <div className="Home-content">
            <div className="video-list panel">
            <h2 className="panel-title"> Video <span>List</span> </h2>
            <ul>
                <VideoChecklist 
                  handleCheck={this.handleCheckToggle.bind(this)} 
                  videos={this.state.videos}/>
            </ul>
            <div className="corner-widgets">
              <Fab 
                className={classes.fab_refresh}
                id="clear-widget"
                color="secondary"
                onClick={() => {
                    this.setState(state=>({selectedAmt: 0}))
                    this.setState(state=>({videos: []}), ()=>{
                      localStorage.setItem( 'videos', JSON.stringify(this.state.videos) );
                      localStorage.setItem('selectedAmount', 0);

                      this.props.SetSelections(0)
                      this.props.ClearList();
                  })
                }}
                aria-label="Add Video"><RefreshIcon />
              </Fab>
              <Fab 
                className={classes.fab_add}
                color="primary"
                onClick={() => {
                  this.setState(state=>({expanded: !state.expanded}))
                }}
                aria-label="Add Video"><AddIcon />
              </Fab>
            </div>
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <AddVideoChecklist AddVideoChecklist={this.handleAddVideoChecklist.bind(this)} videos={this.state.videos}/>
            </Collapse>

            </div>
            <div className="param-list panel">
                <h2 className="panel-title"> <span>Parameters</span> </h2>
                <form>
                    <ParameterInputs home = {this.state}
                                    handleParamChange={this.handleParamChange.bind(this)}
                                    handleContinue={this.props.onChange} 
                    />
                    <div>
                      <ConditionalLink to="/video-showcase">
                        <Fab 
                          variant="extended"
                          className={classes.fab}
                          color="primary"
                          onClick={this.props.onContinue("VideoShowcase")}
                          aria-label="Next">
                        <NavigationIcon />Continue</Fab>                      
                      </ConditionalLink>
                    </div>
                </form>
            </div>

        </div>
        <a className="ref-link" href={SourceCode}>download source code</a>
        <a className="ref-link" href={Instructions} download>download instructions</a>
        <a className="ref-link" href="https://www.gnu.org/licenses/agpl-3.0.en.html">license</a>

      </div>
    );
  }
}

export default withStyles(style)(Home);
