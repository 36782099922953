/*
    This program is designed to identify preferences for video (YouTube) content. The program uses
    the multiple-stimulus-without-replacement (MSWO) preference assessment methodology (Carr,
    Nicolson, & Higbee, 2000; Deleon & Iwata, 1996). The design and functions are based on the
    web-based MSWO program described in Curiel, Curiel, Li, Deochand, and Poling (2018)
    Copyright (C) 2019 Antonio Adame assigned to Board of Regents of the University of Texas System.

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/>. */

import React, { Component } from 'react';
import VideoThumbnail from './VideoThumbnail';
import YouTube from 'react-youtube';
import $ from 'jquery';


class VideoItems extends Component {

    constructor() {
        super();
        // When we change the state, react will want to re-render the component. 
        // Re-rendering the component causes some callback functions to re-fire
        //      when we don't want them to. 
        // This variable prevents that by telling our program not to call certain
        //      callbacks when they were called due to a change in the state.
        var stateChange = false;
        this.state= {
            selected_videos: [],
            remainingCount: 0,

            currentVideoPlayingYTID: '',
            currentVideoPlayingIdx: '',
            queuePlayer: false,
        }

        this.loadVideo = this.loadVideo.bind(this);
    }
    // Generate thumbnails for the video display gallery.
    getSelectedVideos = videos => {
        let selected_videos = videos.filter(video =>{
            if (video.checked) return video;
        });
        let items = selected_videos.map(video => {
            return (
                <VideoThumbnail
                    id = {video.id}
                    video = { video }
                    key = {video.index}
                    index = {video.index}
                    youtubeID = {video.youtubeID}
                    loadVideo = {this.loadVideo}
                />
            )
        })
        return items;

    }
    loadVideo = (video, videoYTID, videoIdx) => {
        this.stateChange = false;

        // Clear video first to allow same video to play twice in a row.
        this.setState({currentVideoPlayingYTID: videoYTID});
        this.setState({currentVideoPlayingIdx: videoIdx})
        this.setState({queuePlayer: true}); // Displays MainPlayer.

        // OnPlay Behaviours. 
        this.props.stopTimerFunction(true); // Stop idle timer.
        this.props.resetTimer();
        
        $('body').addClass('disable-click');

        this.props.seenVideos(video);
    }
    shuffleVideos = () => {
        let videos = this.state.selected_videos.slice();
        let len = videos.length;

        videos.map((v, i) => {
            let r = parseInt(Math.random() * len);
            videos[i] = videos[r];
            videos[r] = v;
        })
        this.setState({cancelVideoReload: true}, () => {
            this.setState({selected_videos: videos})
        });

    }
    // Reduce the count of remaining videos in the gallery.
    handleUpdateRemaining = () => {
        let r = this.state.remainingCount;
        r--;
        this.setState({remainingCount: r});
        this.props.videosRemaining(r);
    }
    // Remove video from gallery showcase.
    removeVideo = index => {
        const { selected_videos } = this.state; 
        let newList = selected_videos.filter(video => {
            if (video.key != index) return video;
        });
        this.setState({selected_videos: newList});
        this.setState({currentVideoPlayingIdx: ""});
        this.setState({currentVideoPlayingYTID: ""});

        this.stateChange = true;
    }
    shuffleVideos = () => {
        // randomize order of videos.
        const { selected_videos } = this.state;
        const videos = selected_videos;
        const len = videos.length;

        for (let i = 0; i < len; i++) {
            let target = Math.floor(Math.random() * len);
            this.swap(videos, i, target);
        }

        this.setState({selected_videos: videos});
    }
    swap = (arr, i1, i2) => {
        let tmp = arr[i1];
        arr[i1] = arr[i2];
        arr[i2] = tmp;
    }


    componentWillMount() {
        const videos = JSON.parse( localStorage.getItem('videos') );
        if (videos) {
            this.setState({ selected_videos: this.getSelectedVideos(videos)
            }, ()=>{
                this.setState({remainingCount: this.state.selected_videos.length})
                localStorage.setItem('selectedVideos', JSON.stringify(this.state.selected_videos));
            });
        }
    }
    componentDidMount = () => this.props.ready( true );


    render() {
        const playTime = parseInt(localStorage.getItem('playTime'));
        const playerStyle = {
            position: 'absolute', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 

            height: '100em', 
            width: '100em',
        }
        const opts = {
            playerVars: { 
                autoplay: 1,
                start: 0,
                end: playTime,
                modestbranding: 1,
                disablekb: 1,
                showinfo: 1,

            }
        }
        const {queuePlayer, currentVideoPlayingYTID, currentVideoPlayingIdx } = this.state;
        return(
            <div className="VideoItems">
                <div className="VideoGallery">
                    { this.state.selected_videos }
                </div>
                <div className="MainPlayer" style={{display: queuePlayer ? "flex" : "none"}}>
                    <YouTube
                        videoId={ this.state.currentVideoPlayingYTID }
                        opts = { opts }
                        style={ playerStyle }
                        onPlay={ (e) => {
                            e.target.setVolume(75)
                            this.props.resetTimer();
                        } }
                        onEnd={ () => { 
                            // Execute only when state is not changed.
                            if (!this.stateChange) {
                                this.setState({queuePlayer: false}); // Removes MainPlayer.
                                this.removeVideo(currentVideoPlayingIdx); // Remove Thumbnail from list.

                                this.handleUpdateRemaining(); // Update remaining list.
                                this.props.stopTimerFunction(false); // Resume idle timer.

                                this.shuffleVideos();
                                $('body').removeClass('disable-click');
                            }
                        } }
                    
                    />
                </div>
            </div>
        )
    }

}

export default VideoItems;