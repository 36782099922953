/*
    This program is designed to identify preferences for video (YouTube) content. The program uses
    the multiple-stimulus-without-replacement (MSWO) preference assessment methodology (Carr,
    Nicolson, & Higbee, 2000; Deleon & Iwata, 1996). The design and functions are based on the
    web-based MSWO program described in Curiel, Curiel, Li, Deochand, and Poling (2018)
    Copyright (C) 2019 Antonio Adame assigned to Board of Regents of the University of Texas System.

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/>. */

import React, { Component } from 'react';
import { isAbsolute } from 'path';
import { withStyles } from '@material-ui/core';

import uuid from 'uuid';


import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const style = theme => (
    {
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
      }
    }
)

class AddVideoChecklist extends Component {
    constructor() {
        super();

        this.state= {
            nameError: false,
            idError: false,

            newVideo: {},
            name: "",
            id: "",
        }
    }
    extractYTID = (videoLink) => {
        let videoId = videoLink.split('v=')[1];
        if (videoId) {
            var ampersandPos = videoId.indexOf('&');
            if (ampersandPos != -1) {
                videoId = videoId.substring(0, ampersandPos);
            }

            return videoId;
        }

        return videoLink;
    }
    getName = (e) => {
        if (e.target.value != "") this.setState({nameError: false})
        this.setState({name: e.target.value});
    }
    getYTID = (e) => {
        if (e.target.value != "") this.setState({idError: false})

        var _videoId = this.extractYTID(e.target.value);

        if (_videoId == e.target.value) {
            this.setState({id: _videoId});
            return null;
        }
        this.setState({id: _videoId});
    }
    
    handleSubmit(e) {
        e.preventDefault();

        // Name check
        if (this.state.name == "") {
            this.setState({nameError: true})
        }
        // ID check
        if (this.state.id == "") {
            this.setState({idError: true})
        }

        if (this.state.name != "" && 
            this.state.id != "") {
                this.setState({
                    newVideo: {
                        id: uuid.v4(),
                        index: this.props.videos.length,
                        name: this.state.name,
                        youtubeID: this.state.id,
                        youtubeLink: `https://www.youtube.com/watch?v=${this.state.id}`,
                        checked: true,
                    }
                }, function() {
                    this.props.AddVideoChecklist(this.state.newVideo);
                })
        }

    }

    render() {
        const {classes} = this.props;
        
        return (

            <div className="AddVideoChecklist">
                <div className="container">
                    <h1 className="panel-subtitle"><span>Add</span> Video</h1>

                    <form noValidate autoComplete='off'> 
                    <div className="row">
                        <TextField
                            error={this.state.nameError}
                            id="outlined-input"
                            label="Name"
                            className={classes.textField}
                            type="text"
                            name="Name"
                            autoComplete="Video Name"
                            margin="normal"
                            variant="outlined"
                            onChange={this.getName}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            error={this.state.idError}
                            id="outlined-input"
                            label="URL or ID"
                            className={classes.textField}
                            type="text"
                            name="Video Name"
                            autoComplete="Video URL"
                            margin="normal"
                            variant="outlined"
                            onChange={this.getYTID}
                        />
                    </div>
                    <div className="row">
                        <Button onClick={this.handleSubmit.bind(this)} variant="contained" color="primary" >Add Video</Button>
                    </div>
                    </form>


                </div>
            </div>
        )
    }

}

export default withStyles(style)(AddVideoChecklist);