/*
    This program is designed to identify preferences for video (YouTube) content. The program uses
    the multiple-stimulus-without-replacement (MSWO) preference assessment methodology (Carr,
    Nicolson, & Higbee, 2000; Deleon & Iwata, 1996). The design and functions are based on the
    web-based MSWO program described in Curiel, Curiel, Li, Deochand, and Poling (2018)
    Copyright (C) 2019 Antonio Adame assigned to Board of Regents of the University of Texas System.

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/>. */

import React, { Component } from 'react';
import Chart from './Chart';
import Button from '@material-ui/core/Button';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import saveAs from 'file-saver';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
      width: '10%',
    },
});
// Combines the results of watched videos and returns the merged results.
const MergeResults = (videos, sectionLen) => {
    let seenVideoValues = {};
    if (videos) {
        videos.map((video, index)=> {
            // the value of each individual video across every repetition.
            // idea is that it separates in this fashion: [ [1,2,3],[2,1,3],[3,2,1] ]...
            // for 3 repetitions & 3 videos.
            let valueIndex = 0;
            if(video) valueIndex = (index % sectionLen)+1; 
            
            let percentageRating = (1/valueIndex)*100;
            if (video) {
                if (!(video.id in seenVideoValues)) {
                    seenVideoValues[video.id]=percentageRating;
                }
                else {
                    seenVideoValues[video.id]+=percentageRating;
                }
            }
        });
    }
    return seenVideoValues;
}
class PreferenceResults extends Component {	
    constructor() {
        super();
        this.state = {
            chartData: {},
        }
    }

    componentWillMount() {
        let seenVideos = localStorage.getItem('seenList');
        seenVideos = JSON.parse(seenVideos);

        let selectedVideos = localStorage.getItem('selectedVideos');
        selectedVideos = JSON.parse(selectedVideos);

        let repeatAmount = localStorage.getItem('repeatAmount');
        let repetitionsRemaining = localStorage.getItem('currentRepeatAmount');
        let actualRepetitions = repeatAmount - repetitionsRemaining;
        
        //Reassign indexes based on seen order.
        if (seenVideos) {
            seenVideos.map((video, index) => {
                if (video) video.index = index;
            });
        }
        //Assign a preference statistic result to each video based on the order it was seen.
        let results = MergeResults(seenVideos, selectedVideos.length);
        for (var id in results) {
            let r = results[id];
            //results[id] = r/selectedVideos.length;
            results[id] = r/actualRepetitions;
        }

        this.setChartData(selectedVideos, results);
    }
    setChartData = (videos, data) => {
        let results = []; // key: video.id, value: preference score.
        for (let key in data) {
            for(let i = 0; i < videos.length; i++){
                if (videos[i].props.id == key) {
                    // some titles won't render on chart if name is too long, 
                    // we can shorten them and add a "..." at the end.
                    let titleLen  = videos[i].props.video.name.length;
                    let shortenedVideoTitle = videos[i].props.video.name;
                    if (titleLen >= 13) {
                        shortenedVideoTitle = shortenedVideoTitle.substr(0,13)+"...";
                    }
                    let obj = {};
                    obj['name'] = shortenedVideoTitle;
                    obj['score'] = data[key];
                    results.push(obj);
                    break;
                }
            }
        }
        let labels = [];
        let scores = [];
        results.map(r=>{
            labels.push(r.name);
            scores.push(r.score);
        })
        this.setState({chartData: {
            labels: labels,
            datasets: [
                {
                    label: 'Preference Score',
                    data: scores,
                    backgroundColor:[
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)'
                      ]
                }
            ]
        }});
    }
    getPreferenceData = () => {
        const { seenVideos } = this.props;
        if (seenVideos) {
            let length = seenVideos.length;
            seenVideos.map((video, i) => {
                let value = (1 / (i+1))*100;
                video['preferenceStat'] = value;
            })
        }
    }

    // Saves canvas instance as a png file. (IE9+, FireFox and Chrome support).
    saveChart = () => {
        $("#bar-chart").get(0).toBlob(blob=>{
            saveAs(blob, "PreferenceResults.png")}
        );
    }
	render() {
        const { classes } = this.props;

		return (
            <div className="PreferenceResults" style={{color: 'white'}}>
            
                <h1 className="page-title">Results</h1>
                <Chart chartData={this.state.chartData}/>
                <div className="preference-results-options">
                <Link className="start-over-link" to="/">
                    <Button onClick={this.handleReset} size="large" variant="contained" color="secondary" 
                        className={classes.button}> Start Over</Button></Link>
                   
                    <Button onClick={this.saveChart.bind(this)} size="large" variant="contained" className={classes.button}>
                        Save as PNG
                    </Button>
                </div>
            </div>
		);
	}
}
 
export default withStyles(styles)(PreferenceResults);