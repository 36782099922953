/*
    This program is designed to identify preferences for video (YouTube) content. The program uses
    the multiple-stimulus-without-replacement (MSWO) preference assessment methodology (Carr,
    Nicolson, & Higbee, 2000; Deleon & Iwata, 1996). The design and functions are based on the
    web-based MSWO program described in Curiel, Curiel, Li, Deochand, and Poling (2018)
    Copyright (C) 2019 Antonio Adame assigned to Board of Regents of the University of Texas System.

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/>. */

import React, { Component } from 'react';
import VideoItems from './VideoItems';
import ReactLoading from 'react-loading';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


import $ from 'jquery';
import { runInThisContext } from 'vm';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
});

class VideoShowcase extends Component {
    constructor() {
        super();
        this.currentIdleTimer = 0;
        this.interval = null;
        this.assessment_ready = false; //Redirects to assessment results when true.

        this.state = {
            assessment_ready: false,
            ready: false,
            assessment_repeat: false,
            idleRedirect: false,
            stopTimer: false,
            timer: 0, //Displays timer value on page.
            repeatAmount: 0,
            seenList: [],
            remaining: 0,
        }
    }
    componentWillMount() {
        /* load previously watched videos from previous 
            repetitions. */

        let seenList = localStorage.getItem('seenList');
        this.setState({seenList: JSON.parse(seenList)});

    }
    componentDidMount() {

        let repetitions = localStorage.getItem('repeatAmount');
        let currRepetitionsRemaining = localStorage.getItem('currentRepeatAmount');
        if (!currRepetitionsRemaining || currRepetitionsRemaining <= 0) {
            currRepetitionsRemaining = repetitions;
        }

        /* Which repetition are we at currently. */
        localStorage.setItem('currentRepeatAmount', currRepetitionsRemaining-1);

        /* load some crucial data (timer, repetitions remaining). */
        this.setState({timer: localStorage.getItem('idleTimeout')});
        this.setState({repeatAmount: currRepetitionsRemaining - 1});

    }

    resetTimer = () => this.currentIdleTimer = 0;

    timerIncrement = () => {
        let idleTime = localStorage.getItem('idleTimeout');

        /* Update state for displayed timer (not on current version). */
        this.setState({timer: (idleTime-this.currentIdleTimer)}); 

        if (this.currentIdleTimer >= idleTime) {
            this.props.SeenVideos(localStorage.getItem('seenList'));

            /* Set remaining videos to null so they can be 
                counted with a score of 0 (not seen). */
            let remaining = this.state.remaining;
            for (let i = 0; i < remaining; i++) {
                this.handleSeenVideos(null);
            }            
            /* Reduce the amount of repetitions remaining. */
            let r_amt = this.state.repeatAmount - 1;

            /* Reload window until repetitions have been 
                exhausted. */
            if (r_amt < 0) this.setState({assessment_ready: true})
            else {
                window.location.reload();
            }
        }
        this.currentIdleTimer = this.currentIdleTimer + 1;

    }


    setReady = videosLoaded => {
        this.setState({ready: videosLoaded});

        //Start idle timer when videos have been loaded.
        if (videosLoaded) {
            this.interval = setInterval(()=>{
                if (!this.state.stopTimer) this.timerIncrement();
                else this.currentIdleTimer = 0;
            }, 1000)
        }
    }
    stopTimerFunction = val => {
        this.setState({stopTimer: val})
    }
    handleSeenVideos = video => {
        const { seenList } = this.state;
        let new_seen = seenList;
        new_seen.push(video);
        this.setState({seenList: new_seen});
        localStorage.setItem('seenList', JSON.stringify(seenList));

    }
    handleRemaining = amt => {
        this.props.SeenVideos(localStorage.getItem('seenList'));
        this.setState({remaining: amt});
        // Once no more videos remain in gallery.
        if (amt == 0) {
            let r_amt = this.state.repeatAmount - 1;

            // Once repetitions have exhausted.
            if (r_amt < 0) this.setState({assessment_ready: true})
            else {
                // Reload window if the repeitions have not finished.
                window.location.reload();
            }
        }
    }
    componentWillUnmount = () => clearInterval(this.interval);
    assessmentReady = () => this.setState({assessment_ready: true})

    render() {
        const { ready, idleRedirect } = this.state;
        const { classes } = this.props;

        if (this.state.assessment_ready) {
            return(
                <div className="AssessmentCompleteScreen">
                    <h1 className="page-title">Assessment Complete!</h1>
                    <div style={{ width: '100%', textAlign: 'center'}} 
                        className="btn-container">
                        <Link to="/assessment-results/">
                            <Button variant="contained" color="primary" 
                                className={classes.button}
                                size="large">
                                See results
                            </Button>
                        </Link>
                        <Link to="/">
                            <Button size="large" variant="contained" color="secondary" 
                                className={classes.button}> Start Over </Button>
                        </Link>
                    </div>
                </div>
            )
        }
        //repeat var is true if all videos in a session have been watched. 
        //idleRedirect var is true if the idle timer has run out.
        if (this.state.idleRedirect) {
            this.setState({assessment_ready: true});
        }

        return(
            <div className="VideoShowcase">

                <div>
                    <VideoItems videosRemaining = { this.handleRemaining.bind(this) }
                                seenVideos = { this.handleSeenVideos.bind(this) }
                                stopTimerFunction = { this.stopTimerFunction.bind(this) } 
                                resetTimer = { this.resetTimer.bind(this) }
                                ready = { this.setReady.bind(this) } />
                </div>
            </div>
        )

    }

}

export default withStyles(styles)(VideoShowcase);