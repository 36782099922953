/*
    This program is designed to identify preferences for video (YouTube) content. The program uses
    the multiple-stimulus-without-replacement (MSWO) preference assessment methodology (Carr,
    Nicolson, & Higbee, 2000; Deleon & Iwata, 1996). The design and functions are based on the
    web-based MSWO program described in Curiel, Curiel, Li, Deochand, and Poling (2018)
    Copyright (C) 2019 Antonio Adame assigned to Board of Regents of the University of Texas System.

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/>. */

import React, { Component } from 'react';

import './App.scss';
import { BrowserRouter as Router, Route, Switch, withRouter, Link } from "react-router-dom";
import { withStyles, MuiThemeProvider } from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import Home from './components/Home';
import VideoShowcase from './components/VideoShowcase/VideoShowcase';
import PreferenceResults from './components/PreferenceResults';

import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';

/*
  To whom it may concern, 
    I apologize if you find this code hard to read. I'm new to React c:
    Integrating Redux might be a good long-term solution.
*/

const theme = createMuiTheme({
  palette: {
    primary: red,
  }
})
const style = theme => (
  {
    snack: {
      marginTop: "10px"
    },
    success: {
      backgroundColor: green[600],
    },
    neutral: {
      backgroundColor: blue[800],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    icon: {
      marginRight: theme.spacing.unit,
    },
    message: {
      display: "flex",
      alignItems: "center",
    }
  }
)

class App extends Component {
  constructor() {
    super();

    this.state = {
      playTime: 1,
      idleTimeout: 1,
      repeatAmount: 1,
      videos: [],
      selectedAmt: 0,

      seenVideos: [],

      errorOpen: false,
      successOpen: false,
      clearOpen: false,
      message: "",
      error_message: "",
      neutral_message: "",
    }
  }
  handleContinue = dest => event => {
    let sa = parseInt( localStorage.getItem('selectedAmount') );
    if (!sa) sa = 0;
    if (sa > 5) {
        this.setState({
          errorOpen: true,
          error_message: "Only 5 or less videos may be selected"
        });
    }
    else if (sa <= 0) {
        this.setState({
          errorOpen: true,
          error_message: "No videos added or selected"
        });
    }
    else return true;
  }
l
  handleSetTime = (name, time) => {
    this.setState({[name]: time})
    localStorage.setItem([name], time);
  }
  handleClearList = () => {
    this.setState({videos: [],
                    clearOpen: true,
                    neutral_message: "Video list cleared."
    });
  }
  handleSetVideo = videos => {
      this.setState({
        successOpen: true,
        message: "Video Added!",
        videos: videos
      });

  }
  handleSeenList = videos => {
    this.setState({seenVideos: videos});
    localStorage.setItem('seenVideos', JSON.stringify(videos))
  }
  handleClose = name => this.setState({[name]: false})
  handleSelections = amt => this.setState({selectedAmt: amt})

  render() {
    const {classes} = this.props;
    return(
      <div className="App">   
        <Router>
          <Switch>
            <Route exact path="/" render={
              props=><Home onContinue={this.handleContinue.bind(this)}
                          handleSeen={this.handleSeenList.bind(this)}
                          SetTime={this.handleSetTime.bind(this)}
                          SetVideo={this.handleSetVideo.bind(this)}
                          ClearList={this.handleClearList.bind(this)}
                          SetSelections={this.handleSelections.bind(this)}/>} 
                          />
            <Route path="/video-showcase/" render={
              props=><VideoShowcase SeenVideos={this.handleSeenList.bind(this)}/> 
            }/>

            <Route path="/assessment-results/" render={
              props=><PreferenceResults seenVideos={this.state.seenVideos}
                                        videoList={this.state.videos}/>
            }/>
          </Switch>
        </Router>


        {/* error message */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.snack}
          open={this.state.errorOpen}
          autoHideDuration={2000}
          onClose={()=>this.handleClose("errorOpen")}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
        <SnackbarContent
          className={classes.error}
          message={<span className={classes.message} >
                      <ErrorIcon className={classes.icon}/>
                      {this.state.error_message}
                    </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={()=>this.handleClose("errorOpen")}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
        </Snackbar>
        {/* list cleared message */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.snack}
          open={this.state.clearOpen}
          autoHideDuration={1000}
          onClose={()=>this.handleClose("clearOpen")}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
        <SnackbarContent
          className={classes.neutral}
          message={<span className={classes.message} >
                      <WarningIcon className={classes.icon}/>
                      {this.state.neutral_message}
                    </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={()=>this.handleClose("clearOpen")}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
        </Snackbar>
        {/* success message */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.snack}
          open={this.state.successOpen}
          autoHideDuration={1000}
          onClose={()=>this.handleClose("successOpen")}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
        <SnackbarContent
          className={classes.success}
          message={<span className={classes.message} >
                      <CheckCircleIcon className={classes.icon}/>
                      {this.state.message}
                    </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={()=>this.handleCwlose("successOpen")}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
        </Snackbar>   
                  
      </div>  

    );
    
  }
    
}

export default withRouter(withStyles(style)(App));
